import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  step: css`
    border-radius: 16px;
    background: ${theme.palette.common.white};
  `,
  step__icon: css`
    align-self: center;
  `
};
