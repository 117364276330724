import { css } from "@emotion/react";

export const styles = {
  box: css`
    height: 124px;
    padding-top: 16px;
    background: linear-gradient(91.24deg, #0089cf 48.94%, #005f90 100%);
  `,
  button: css`
    width: 200px;
    height: 42px;
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  `
};
