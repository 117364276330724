import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  container: (isSmallScreen: boolean) => css`
    text-align: ${isSmallScreen ? "-webkit-center" : ""};
    background: linear-gradient(96.52deg, ${theme.palette.warning.main} 44.87%, ${theme.palette.warning.light} 100%);
  `,
  stack__mediaQuery: css`
    @media (max-width: 1420px) {
      flex-direction: column;
    }
  `
};
