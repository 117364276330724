import React from "react";

import { useDisclose } from "@/hooks/utilHooks";
import theme from "@/styles/themes/theme";
import { Document } from "@contentful/rich-text-types";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Gauge } from "@phosphor-icons/react";

import { ModalKmSimulator } from "../ModalKmSimulator";
import { styles } from "./styles";

type KmSimulatorProps = {
  simulatorAccumulationInformativeText: Document;
};

export const KmSimulator: React.FC<KmSimulatorProps> = ({ simulatorAccumulationInformativeText }) => {
  const themeMaterial = useTheme();
  const simulatorKm = useDisclose();
  const isSmallScreen = useMediaQuery(themeMaterial.breakpoints.down("sm"));

  const handleSimulator = () => {
    simulatorKm.onOpen();
  };
  return (
    <>
      <Box width="100%" css={styles.box}>
        <Grid container item alignContent="center" spacing={2} justifyContent="center" height="100%">
          <Grid item mr={isSmallScreen ? 0 : 8}>
            <Grid item>
              <Typography fontSize={24} textAlign={isSmallScreen ? "center" : "initial"} color={theme.palette.common.white}>
                SIMULADOR DE PONTOS KMV
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize={16} color={theme.palette.common.white} fontWeight={600}>
                Simule quantos pontos KMV você pode ganhar!
              </Typography>
            </Grid>
          </Grid>
          <Grid item alignSelf="center" ml={isSmallScreen ? 0 : 4} mt={isSmallScreen ? -1 : 0}>
            <Button variant="contained" color="primary" onClick={handleSimulator} css={styles.button}>
              <Gauge size={24} color={theme.palette.common.black} />
              <Typography ml={1} fontWeight={400} color={theme.palette.common.black}>
                SIMULAR AGORA
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>

      <ModalKmSimulator
        isOpen={simulatorKm.isOpen}
        onClose={simulatorKm.onClose}
        simulatorAccumulationInformativeText={simulatorAccumulationInformativeText}
      />
    </>
  );
};
