import theme from "@/styles/themes/theme";
import { ArrowRight } from "@phosphor-icons/react";

import { Box } from "@mui/material";

export const Arrow: React.FC = () => {
  return (
    <Box
      sx={{ mx: { lg: -3, sm: 0, xs: -3 }, my: { lg: 0, sm: -3, xs: -3 } }}
      alignSelf="center"
      style={{
        minWidth: 64,
        minHeight: 64,
        borderRadius: 50,
        zIndex: 1,
        boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
        backgroundColor: theme.palette.warning.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Box
        sx={{ transform: { lg: "rotate(0deg)", sm: "rotate(90deg)", xs: "rotate(90deg)" } }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ArrowRight size={48} color={theme.palette.common.white} />
      </Box>
    </Box>
  );
};
