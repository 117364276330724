import { GetServerSideProps } from "next";

import { CarouselFootball, CarouselMain } from "@/components/Carousel";
import { FootballCardProps } from "@/components/Carousel/CarouselFootball/FootballCard/types";
import { Container } from "@/components/Container";
import { DownloadApp } from "@/components/DownloadApp";
import { DownloadAppMobile } from "@/components/DownloadAppMobile";
import { HomePageCarousels } from "@/components/HomePageCarousels";
import { StepByStep } from "@/components/StepByStep";
import { FootballApi } from "@/services/api/football.api";
import { HomePageResponseProps, HomePageService } from "@/services/contentful/homePage";
import { PlatformConfigurationService } from "@/services/contentful/platformConfiguration";
import theme from "@/styles/themes/theme";
import { hasEmptyStringProperty } from "@/utils/string";
import { Document } from "@contentful/rich-text-types";
import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import { KmSimulator } from "@/components/KmSimulator/KmSimulator";

interface Props {
  footballCarouselItems: FootballCardProps[];
  homePageProps: HomePageResponseProps;
  simulatorAccumulationInformativeText: Document;
}

const Home: React.FC<Props> = ({ footballCarouselItems, homePageProps, simulatorAccumulationInformativeText }) => {
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack>
      <CarouselMain items={homePageProps?.mainCarousel?.items} />

      {hasEmptyStringProperty(homePageProps.downloadAppBanner) ? null : isMd ? (
        <DownloadApp {...homePageProps.downloadAppBanner} />
      ) : (
        <DownloadAppMobile {...homePageProps.downloadAppBanner} />
      )}

      <HomePageCarousels carousels={homePageProps?.carousels ?? []}>
        {homePageProps.showFootballCarousel && footballCarouselItems.length > 0 ? (
          <Container py={8}>
            <CarouselFootball items={footballCarouselItems} defaultShield={""} />
          </Container>
        ) : null}
      </HomePageCarousels>

      <Grid container item my={4}>
        <KmSimulator simulatorAccumulationInformativeText={simulatorAccumulationInformativeText} />
      </Grid>

      <Box mb={2}>
        <StepByStep
          stepByStepTitle={homePageProps.steps?.stepByStepTitle}
          firstStep={homePageProps.steps?.firstStep}
          secondStep={homePageProps.steps?.secondStep}
          thirdStep={homePageProps.steps?.thirdStep}
        />
      </Box>
    </Stack>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  const [footballCarouselItems, homePageProps, { menuItems, simulatorAccumulationInformativeText, locationCompany }] = await Promise.all([
    FootballApi.listMatchs(),
    HomePageService.getData(),
    PlatformConfigurationService.getData()
  ]);

  return { props: { footballCarouselItems, homePageProps, menuItems, simulatorAccumulationInformativeText, locationCompany } };
};

export default Home;
