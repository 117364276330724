/* eslint-disable @typescript-eslint/no-explicit-any */
export const createStringUrl = (baseUrl: string, urlParams: Record<string, unknown>) => {
  const queryKeys = Object.keys(urlParams);

  if (queryKeys.length === 0) {
    return baseUrl;
  }

  const concatedParams = queryKeys.reduce((queryString, queryKey) => {
    const value = urlParams[queryKey];

    if (value) {
      const keyValueString = `${queryKey}=${value}`;
      return queryString ? `${queryString}&${keyValueString}` : keyValueString;
    }

    return queryString;
  }, "");

  return `${baseUrl}?${concatedParams}`;
};

export const replaceAccents = (input?: string) => {
  if (!input) {
    return "";
  }

  /* Normalize the string to remove diacritics (accents) */
  const sanitized = input.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  return sanitized;
};

type AnyObject = { [key: string]: any };

export function hasEmptyStringProperty(obj: AnyObject): boolean {
  for (const key in obj) {
    if (typeof obj[key] === "string" && obj[key].trim() === "") {
      return true;
    }
  }
  return false;
}
