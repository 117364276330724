import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  homepagecarousels: css`
    background-color: ${theme.palette.grey[200]};
    & > div:nth-of-type(even) {
      background-color: ${theme.palette.common.white};
    }
  `
};
