import React from "react";

import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ArrowsLeftRight, Basket, Megaphone } from "@phosphor-icons/react";

import { Container } from "../Container";
import { Arrow } from "./Arrow";
import { Step } from "./Step/Step";
import { styles } from "./styles";
import { StepByStepProps } from "./type";

export const StepByStep: React.FC<StepByStepProps> = ({ firstStep, secondStep, thirdStep, stepByStepTitle }) => {
  const themeMaterial = useTheme();
  const isDownArrow = useMediaQuery(themeMaterial.breakpoints.down("md"));

  return (
    // eslint-disable-next-line react/no-unknown-property
    <Container
      pb={6}
      containerTitle={
        <Typography component="span" fontSize={24} textAlign="center" color="white">
          {stepByStepTitle}
        </Typography>
      }
      css={styles.container(isDownArrow)}
    >
      <Stack width="100%" justifyContent="center" direction={{ lg: "row", sm: "column" }} mt={1}>
        <Step Icon={Basket} description={firstStep?.description} linkText={firstStep.title} link={firstStep.link} />

        <Arrow />

        <Step Icon={ArrowsLeftRight} description={secondStep?.description} linkText={secondStep.title} link={secondStep.link} />

        <Arrow />

        <Step Icon={Megaphone} description={thirdStep?.description} linkText={thirdStep.title} link={thirdStep.link} />
      </Stack>
    </Container>
  );
};
