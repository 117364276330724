import theme from "@/styles/themes/theme";
import { Box, Stack, Typography } from "@mui/material";
import Link from "next/link";
import { styles } from "./styles";
import { StepProps } from "./types";

export const Step = ({ Icon, description, linkText, link }: StepProps) => {
  return (
    <Box px={3} css={styles.step} p={4} display="flex" justifyContent="center" alignItems="center">
      <Stack spacing={2} justifyContent="center" direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}>
        <Icon size={64} color={theme.palette.warning.main} weight="regular" css={styles.step__icon} />

        <Stack width={247} justifyContent="center" spacing={1}>
          <Typography fontSize={16}>{description}</Typography>

          <Typography
            fontSize={13}
            fontWeight={600}
            color={theme.palette.secondary.main}
            variant="button"
            sx={{ alignSelf: { md: "flex-start", sm: "center", xs: "center" } }}
          >
            <Link href={link} style={{ textDecoration: "none", color: "inherit", display: "flex" }}>
              {linkText} »
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
